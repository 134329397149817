import * as React from "react";
import { graphql } from "gatsby";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import Heading from "components/core/Typography/Heading";
import { PageContainer } from "styles/global.style";
import {
  EarthContainer,
  EarthInnerContainer,
  MissionContainer,
  MissionInnerContainer,
  Section,
  StatementContainer,
} from "styles/mission.style";
import Fade from "components/core/Animation/extended/Fade";
import StaticImg from "components/core/StaticImg";

interface MissionPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: MissionPageProps) => {
  const { site } = data;
  const { t } = useTranslation();

  return (
    <GlobalWrapper
      withLayout
      layoutSettings={{ alternativeColors: true }}
      location={location}
    >
      <SEO
        title={t("pages.mission.seo.title")}
        description={t("pages.mission.seo.description")}
        image={{
          relativePath: "meta/customerly-mission.jpg",
          alt: "Customerly Mission",
        }}
      />

      <MissionContainer>
        <PageContainer>
          <MissionInnerContainer>
            <Heading level={1} weight={"bold"}>
              Our Mission at Customerly is to Help and Inspire at least 1
              Billion People
            </Heading>
          </MissionInnerContainer>
        </PageContainer>
        <EarthContainer>
          <EarthInnerContainer>
            <StaticImg alt={"Earth"} src={"pages/mission/earth.png"} />
          </EarthInnerContainer>
        </EarthContainer>
      </MissionContainer>
      <PageContainer>
        <StatementContainer>
          <Section>
            <Fade direction={"up"}>
              <Heading level={2} weight={600}>
                We Believe...
              </Heading>
            </Fade>
            <Fade direction={"up"} delay={400}>
              <p>
                everyone on Earth has a mission and we are here to help them to
                achieve that mission.
              </p>
            </Fade>
            <Fade direction={"up"} delay={800}>
              <p>
                every human being has the right to succeed and excel in life.
              </p>
            </Fade>
            <Fade direction={"up"} delay={1200}>
              <p>
                in the power of the willingness of the people to achieve their
                goals.
              </p>
            </Fade>
            <Fade direction={"up"} delay={1600}>
              <p>
                that every person on earth should be treated with respect and
                care.
              </p>
            </Fade>
            <Fade direction={"up"} delay={2000}>
              <p>
                that a person with a dream is more powerful than everything
                else.
              </p>
            </Fade>
          </Section>
          <Section>
            <Fade direction={"up"}>
              <Heading level={2} weight={600}>
                Our Dream...
              </Heading>
            </Fade>
            <Fade direction={"up"} delay={400}>
              <p>
                is to help those dreamers to achieve their goals in life and
                succeed in whatever venture they want to invest their life in.
              </p>
            </Fade>
            <Fade direction={"up"} delay={800}>
              <p>
                is to create a strong community of dreamers that want to improve
                their lives and the lives of their customers.
              </p>
            </Fade>
            <Fade direction={"up"} delay={1200}>
              <p>
                is to create a better world through better customer service.
              </p>
            </Fade>
          </Section>
          <Section>
            <Fade direction={"up"}>
              <Heading level={2} weight={600}>
                How?
              </Heading>
            </Fade>
            <Fade direction={"up"} delay={400}>
              <p>
                We love to help businesses to grow and help their customers
                through an easy to use and powerful suite.
              </p>
            </Fade>
            <Fade direction={"up"} delay={800}>
              <p>
                We put our best effort to improve the services people use on
                daily bases to be in touch with each other.
              </p>
            </Fade>
            <Fade direction={"up"} delay={1200}>
              <p>
                Our endless care about how we can improve your exerience to make
                your life easier and save your precious time.
              </p>
            </Fade>
          </Section>
          <Section>
            <Fade direction={"up"}>
              <Heading level={2} weight={600}>
                What?
              </Heading>
            </Fade>
            <Fade direction={"up"} delay={400}>
              <p>
                We built the most complete suite to let you handle
                communications with your customers.
              </p>
            </Fade>
            <Fade direction={"up"} delay={800}>
              <p>
                We focused on customer satisfaction tools to make you focus on
                your customer happiness above everything else.
              </p>
            </Fade>
            <Fade direction={"up"} delay={1200}>
              <p>
                We delivered to the world a marketing automation tool that keeps
                you human.
              </p>
            </Fade>
            <Fade direction={"up"} delay={800}>
              <p>
                We are constantly releasing helpful contents to share the
                knowledge with the entire world.
              </p>
            </Fade>
          </Section>
          <Section>
            <Fade direction={"up"}>
              <Heading level={2} weight={600}>
                Our Vision...
              </Heading>
            </Fade>
            <Fade direction={"up"} delay={400}>
              <p>
                is to create an environment where you feel the connection with
                the people you serve everyday.
              </p>
            </Fade>
            <Fade direction={"up"} delay={800}>
              <p>
                is to create a great community of like-minded entrepreneurs with
                the dream of changing the world.
              </p>
            </Fade>
            <Fade direction={"up"} delay={1200}>
              <p>
                is to constantly share the knowledge behind successful practices
                to grow businesses and empower people lives.
              </p>
            </Fade>
          </Section>
        </StatementContainer>
      </PageContainer>
    </GlobalWrapper>
  );
};

export const missionPageQuery = graphql`
  query MissionQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
